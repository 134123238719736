.social {
  display: flex;
  justify-content: space-between;
  width: 15vw;

  & > a {
    color: transparent;

    & > svg {
      width: 2vw;
    }
  }
}

.stores {
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-items: baseline;
  width: 11vw;

  & > a {
    color: transparent;

    & > svg {
      width: 7vw;
    }
  }
}

.socialResponsive {
  display: flex;
  // align-items: center;
  justify-content: space-evenly;

  // width: 35vw;
  // min-width: 200px;

  & > div > a {
    color: white;
  }
}

.storesResponsive {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  align-items: baseline;
  width: 30vw;
  min-width: 150px;

  & > a {
    color: transparent;

    & > svg {
      width: 30vw;
      max-width: 90px;
    }
  }
}

.navigationMenu {
  & a {
    font-size: 15px;
    text-decoration: none;
    color: var(--main-color);
    font-family: 'kufi-regular';
    margin-bottom: 10px;
  }
}

.footer {
  background: var(--color-dodger-blue);
  color: white;
  text-align: center;
  // padding: 10px;
  width: 100%;
  margin: 0;
  display: grid;
  // padding: 40px;
  align-items: center;
  position: absolute;
  height: 10rem;
  bottom: 0;
  > div {
    margin-bottom: 30px;
  }

  @media (max-width: 767px) {
    height: 20rem;
  }
}

@media all and (min-width: 768px) {
  .footer {
    grid-template-columns: 33.3% 33.3% 33.3%;

    > div {
      margin-bottom: 0px;
    }
  }
}

:global(.ar) {
  .footer {
    direction: rtl;
  }
}

.links {
  display: flex;
  justify-content: center;

  > div > a {
    text-decoration: none !important;
    color: white !important;
  }
}

.icon {
  cursor: pointer;
}

.youTube {
  &:hover {
    > path {
      fill: var(--color-black);
    }
  }
}

.instagram {
  &:hover {
    > g {
      stroke: var(--color-black);
    }
  }
}

.facebook {
  &:hover {
    > g {
      > path {
        fill: var(--color-black);
      }
    }
  }
}

.twitter {
  &:hover {
    > path {
      fill: var(--color-black);
    }
  }
}

.hovered {
  &:hover {
    color: var(--color-black) !important;
  }
}
