.layout {
  display: flex;
  flex-flow: column nowrap;
  position: relative;
  min-height: 100vh;
}

.line {
  width: 100%;
  height: 15px;
  background-color: var(--color-dodger-blue);
}

.inner {
  display: flex;
  flex-flow: column nowrap;
  margin-top: 20px;
  padding-bottom: 12rem !important;
  @media(max-width: 767px) {
    padding-bottom: 22rem !important;
  }
}

.aside {
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  // max-height: 100vh;
  justify-content: center;
  padding: 32px;
  border-radius: 20px;
  background: transparent linear-gradient(224deg, #c7deec 0%, #339dff 100%) 0%
    0% no-repeat padding-box;
}

:global(.ar) {
  .aside {
    align-items: flex-end;
  }
}

:global(.en) {
  .aside {
    align-items: flex-start;
  }
}

.bgImg {
  max-width: 200px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0.5;
}

.langSelect {
  position: absolute;
  top: 32px;
}

.footer{
  // display: flex;
  min-width: fit-content;
  z-index: 900;
  // height: 6vh;
  position: fixed;
  
}
:global(.ar) {
  .langSelect {
    left: 32px;
  }
}

:global(.en) {
  .langSelect {
    right: 32px;
  }
}

.asideContent {
  margin-top: 30px;
}

.asideTitle {
  color: var(--color-white);
  font-size: 20px;
  line-height: 30px;
}

.asideText {
  margin-top: 15px;
  color: var(--color-white);
  font-size: 40px;
  line-height: 57px;
}

.asideCaption {
  display: flex;
  flex-flow: column nowrap;

  color: var(--color-white);
  font-size: 14px;
  line-height: 20px;

  &:before {
    content: '';
    display: block;
    margin: 15px 0;
    width: 100%;
    height: 1px;
    background-color: var(--color-white);
  }
}

:global(.ar) {
  .asideCaption {
    align-items: flex-end;
  }
}

:global(.en) {
  .asideCaption {
    align-items: flex-start;
  }
}

.main {
  flex: 1;
  margin-top: 10px;
  display: flex;
  flex-flow: column nowrap;
}

:global(.ar) {
  .main {
    align-items: flex-end;
  }
}

:global(.en) {
  .main {
    align-items: flex-start;
  }
}

.logo {
  display: block;
  // width: 282px;
  // height: 80px;
}

.logoImg {
  width: 100%;
  height: 100%;
}

.mainContent {
  width: 100%;
  margin-top: 10px;
}

@media all and (min-width: 1280px) {
  .inner {
    justify-content: space-between;
    margin: 10px 0;
    padding: 0 50px;
    padding-bottom: 5%;
  }

  .bgImg {
    max-width: 350px;
  }

  .aside {
    flex: 1;
    max-width: 860px;
  }

  .main {
    max-width: 540px;
  }

  .asideTitle {
    font-size: 30px;
    line-height: 40px;
  }

  .asideText {
    font-size: 50px;
    line-height: 67px;
  }

  .asideCaption {
    font-size: 20px;
    line-height: 26px;

    &:before {
      margin: 30px 0;
      width: 240px;
    }
  }

  :global(.ar) {
    .inner {
      flex-flow: row nowrap;
    }

    .aside {
      margin-right: 50px;
    }
  }

  :global(.en) {
    .inner {
      flex-flow: row-reverse nowrap;
    }

    .aside {
      margin-left: 50px;
    }
  }
}

@media all and (min-width: 1920px) {
  .inner {
    justify-content: flex-start;
    margin-top: 85px;
    margin-bottom: 50px;
    padding: 0 100px;
  }

  .mainContent {
    margin-top: 10px;
  }

  .aside {
    margin-right: 210px;
  }
}
