.checkboxWrapper {
  display: flex;
  // flex-flow: row nowrap;
  align-items: center;
}

.checkbox {
  position: relative;
  width: 24px;
  height: 24px;
  cursor: pointer;

  &.checkboxDisabled {
    opacity: .32;
    pointer-events: none;
  }

  // &:hover {

  //   .iconWrapper {
  //     border-color: var(--color-blue-primary);
  //   }
  // }

  // &:active {

  //   .iconWrapper {
  //     border-color: var(--color-blue-dark);
  //   }
  // }
}

.field {
  position: absolute;
  top: 0;
  // left: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  margin: 0;
  cursor: pointer;
  opacity: 0;

  &:checked + .iconWrapper {
    background-color: var(--color-dodger-blue);
    border: 2px solid var(--color-dodger-blue);

    .icon {
      color: var(--color-white);
      opacity: 1;
    }
  }
}

.iconWrapper {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border: 1px solid var(--color-mercury);
  border-radius: 5px;
  transition: all .15s ease-out;
}

.icon {
  width: 20px;
  height: 20px;
  opacity: 0;
  transition: all .15s ease-out;
}

.label {
  flex: 1;
  // margin-right: 15px;
  font-size: 20px;
  line-height: 24px;
  color: var(--color-dodger-blue);
}



:global(.ar) {
  .checkboxWrapper {
    flex-flow: row nowrap;
  }
  .field {
    left: 0;
  }
  .label {
    margin-right: 15px;
  }
}

:global(.en) {
  .checkboxWrapper {
    flex-flow: row-reverse nowrap;
  }
  .field {
    right: 0;
  }
  .label {
    margin-left: 15px;
  }
}