.switch {
  position: relative;
  display: inline-block;
  width: 44px;
  height: 26px;
  transition: all .15s ease-in-out;

  &.switchDisabled {
    opacity: .48;
    pointer-events: none;
  }
}

.field {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
  width: 100%;
  height: 100%;
  margin: 0;
  cursor: pointer;
  opacity: 0;

  &:checked ~ .body {
    background-color: var(--color-dodger-blue);
  }

  &:checked ~ .toggle {
    left: 20px;
  }
}

.body {
  width: 100%;
  height: 100%;
  background-color: var(--color-mercury);
  border-radius: 56px;
  transition: all .15s ease-out;
}

.toggle {
  position: absolute;
  top: 2px;
  left: 2px;
  z-index: 2;
  width: 22px;
  height: 22px;
  background-color: var(--color-white);
  border-radius: 50%;
  transition: all .15s ease-out;
}


