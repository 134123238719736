.loading {
  position: relative;
}

.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.centeredX {
  padding-top: 100px;
  padding-bottom: 100px;
  left: 50%;
}

.inner {
  position: absolute;
  display: block;
  box-sizing: border-box;
  border: 2px solid var(--color-dodger-blue);
  border-color: var(--color-dodger-blue) transparent transparent transparent;
  border-radius: 50%;
  animation: loading 1.2s cubic-bezier(.5, 0, .5, 1) infinite;

  &:nth-child(1) {
    animation-delay: -.45s;
  }

  :nth-child(2) {
    animation-delay: -.3s;
  }

  :nth-child(3) {
    animation-delay: -.15s;
  }
}

@keyframes loading {

  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
