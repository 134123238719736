.form {
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
}

.button {
  margin-top: 10px;
  width: 100%;
  height: 60px;
  font-weight: 700;
}

.errorMessage {
  color: var(--color-tamarillo);
}

.infoText {
  margin-left: 6px;
}

.passwordIcon {
  width: 24px;
  height: 24px;
  color: var(--color-dodger-blue);
  cursor: pointer;
}

.forgotPasswordLink {
  font-size: 20px;
  color: var(--color-dodger-blue);
  text-decoration: none;
  cursor: pointer;
  font-family: 'Tahoma', sans-serif;
  &:hover{
    color: var(--color-dodger-blue);
  }
}

.moreInfo {
  font-size: 20px;
  color: var(--color-dodger-blue);
  text-decoration: none;
  cursor: pointer;
  font-family: 'Tahoma', sans-serif;
  &:hover{
    color: var(--color-dodger-blue);
  }
  text-align: end;
}

.moreInfoContainer {
  display: flex;
  justify-content: end;
}

:global(.en) {
  .moreInfoContainer {
    justify-content: start;
  }
}
