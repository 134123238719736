.breadcrumbs {
  display: flex;
  flex-flow: column nowrap;
}

.route {
  display: flex;
  // flex-flow: row nowrap;
  align-items: center;
}

.routeText {
  font-size: 20px;
  line-height: 24px;
  color: var(--color-dodger-blue);
}

.routeIcon {
  // margin-left: 10px;
  width: 24px;
  height: 24px;
  color: var(--color-dodger-blue);
}

.separatorIcon {
  display: block;
  // transform: rotate(-90deg);
  margin: 20px 0;
  width: 10px;
  height: 10px;
  color: var(--color-black);
}

.homeRoute {
  display: flex;
  // flex-flow: row nowrap;
  align-items: center;
  cursor: pointer;

  &:hover {
    .homeRouteText {
      color: rgb(12, 134, 247);
    }

    .homeRouteIcon {
      color: rgb(12, 134, 247);
    }
  }
}

.homeRouteText {
  font-size: 20px;
  line-height: 24px;
  transition: all .15s ease-out;
}

.homeRouteIcon {
  // margin-left: 20px;
  width: 16px;
  height: 16px;
  color: var(--color-dodger-blue);
}

:global(.ar) {
  .route{
    flex-flow: row nowrap;
  }

  .routeIcon{
    margin-left: 10px;
  }

  .homeRoute {
    flex-flow: row nowrap;
  }
  .homeRouteIcon{
    margin-left: 20px;
  }
  .separatorIcon{
    transform: rotate(0deg);
  }
}

:global(.en) {
  .route{
    flex-flow: row-reverse nowrap;
  }
  .routeIcon{
    margin-right: 10px;
  }
  .homeRoute {
    flex-flow: row-reverse nowrap;
  }
  .homeRouteIcon{
    margin-right: 20px;
    transform: rotate(180deg);
  }
  .separatorIcon{
    transform: rotate(-180deg);
  }
}

@media all and (min-width: 960px) {
  .breadcrumbs {
    // flex-flow: row nowrap;
    align-items: center;
    // padding-right: 10px;
  }

  .separatorIcon {
    margin: 0 20px;
    transform: none;
  }


  :global(.ar) {
    .breadcrumbs {
      flex-flow: row nowrap;
      padding-right: 10px;
    }
  }

  :global(.en) {
    .breadcrumbs {
      flex-flow: row-reverse nowrap;
      padding-left: 10px;
    }
  }
}
