.inner {
  display: flex;
  align-items: center;
  cursor: pointer;

  &:after {
    content: "";
    display: block;
    width: 1px;
    height: 40px;
    background-color: var(--color-dodger-blue);
  }
}

.arrowIcon {
  width: 10px;
  height: 10px;
  color: var(--color-dodger-blue);
}

.userIcon {
  width: 30px;
  height: 30px;
  color: var(--color-dodger-blue);
}

.text {
  font-size: 13px;
  line-height: 16px;
  color: var(--color-dodger-blue);
}

.caption {
  font-size: 20px;
  line-height: 24px;
  color: var(--color-dodger-blue);
}

.menu {
  width: 185px;
  background-color: var(--color-white);
  box-shadow: 0px 0px 20px #0000001A;
  border: 1px solid var(--color-silver-chalice);
  border-radius: 10px;
  padding: 10px 0;
} 

.menuItem {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  line-height: 24px;
  color: var(--color-black);
  background-color: var(--color-white);
  padding: 5px 0;
  cursor: pointer;
  transition: all .15s ease-out;

  &:hover {
    background-color: rgba(51, 157, 255, 0.1);
    color: var(--color-dodger-blue);
  }
}


:global(.ar) {
  .inner{
    flex-flow: row nowrap;
    margin-right: 30px;
    &:after {
      margin-left: 30px;
    }
  }
  .arrowIcon{
    margin-right: 20px;
  }
  .userIcon{
    margin-left: 10px;
  }
}

:global(.en) {
  .inner{
    flex-flow: row-reverse nowrap;
    margin-left: 30px;
    &:after {
      margin-right: 30px;
    }
  }
  .arrowIcon{
    margin-left: 20px;
  }
  .userIcon{
    margin-right: 10px;
  }
}