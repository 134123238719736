.info {
  margin-top: 50px;
  padding-bottom: 50px;
  border-bottom: 1px solid var(--color-mercury);
}

.title {
  font-size: 22px;
  font-weight: bold;
  line-height: 22px;
  margin-bottom: 30px;
  color: var(--color-dodger-blue);
}