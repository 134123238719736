.menu {
  display: none;
  position: absolute;
  left: 0;
  right: 0;
  top: 100%;
  background: var(--color-white);
  width: 100%;
  z-index: 1;
  padding: 10px 0;
  box-shadow: 0px 0px 20px #0000001A;
}

.menuOpen {
  display: block;
}

.nav {
  margin-top: 20px;
}

.navItem {
  padding: 10px;
  cursor: pointer;
  transition: all .15s ease-out;

  &:not(.navItemSelected):hover {
    background-color: rgba(51, 157, 255, 0.1);
    color: var(--color-dodger-blue);
  }
}

.navItemSelected {
  background-color: var(--color-dodger-blue);

  .navLink {
    color: var(--color-white);
  }
}

.header {
  padding: 0 10px;
}

.text {
  font-size: 13px;
  line-height: 16px;
  color: var(--color-dodger-blue);
}

.caption {
  font-size: 20px;
  line-height: 24px;
  color: var(--color-black);
}

.logout {
  text-align: right;
  margin-top: 20px;
  padding: 10px;
  cursor: pointer;
  transition: all .15s ease-out;

  &:hover {
    background-color: rgba(51, 157, 255, 0.1);
    color: var(--color-dodger-blue);
  }
}