.page {
  display: flex;
  flex-flow: column nowrap;
  padding: 50px 20px;
  max-width: 1720px;
  margin: 0 auto;
}

.header {
  display: flex;
  flex-flow: column-reverse nowrap;
}

.inner {
  display: flex;
  flex-flow: column nowrap;
  margin-top: 30px;
  border-top: 1px solid var(--color-mercury);
}

.actionCell {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
}

.downloadIcon {
  width: 24px;
  height: 24px;
  color: var(--color-dodger-blue);
  cursor: pointer;
}

:global(.ar) {
  .emptyText {
    margin-top: 30px;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
  }
}

:global(.en) {
  .emptyText {
    margin-top: 30px;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
  }
}

@media all and (min-width: 960px) {
  .header {
    // flex-flow: row-reverse nowrap;
    align-items: center;
    justify-content: space-between;
  }

  :global(.ar) {
    .header {
      flex-flow: row-reverse nowrap;
    }
  }

  :global(.en) {
    .header {
      flex-flow: row nowrap;
    }
  }
}

.filtersAndDownload {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 30px 0;
}

:global(.ar) {
  .filtersAndDownload {
    flex-direction: row-reverse;
  }
}

.headerText {
  font-size: 16px;
}
.text p {
  font-size: 16px;
  white-space: normal;
}
.text p a{
  font-size: 16px;
}
.text {
  font-size: 16px;
}
