.info {
  display: flex;
  flex-flow: column nowrap;
  // border: 1px solid var(--color-mercury);
  border-radius: 10px;
  padding: 40px 0px;
  padding-top: 0;
}

.qrCode {
  align-self: center;
  width: 200px;
  height: 200px;
}

.inner {
  flex: 1;
  // display: grid;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
  // grid-template-columns: 4fr 4fr;
  // margin-top: 50px;
}

.title {
  font-size: 30px;
  line-height: 40px;
  color: var(--color-dodger-blue);
}

:global(.ar) {
  .inner {
    direction: rtl;
  }
  .title {
    text-align: right;
  }
}

:global(.en) {
  .inner {
    direction: ltr;
  }
  .title {
    text-align: left;
  }
}

.main {
  margin-top: 70px;
  display: flex;
  flex-flow: column nowrap;
}

:global(.ar) {
  .main {
    align-items: flex-end;
  }
}

:global(.en) {
  .main {
    align-items: flex-start;
  }
}

.itemsRow {
  display: flex;
  flex-flow: column nowrap;
  margin-top: 30px;

  &:first-child {
    margin-top: 0;
  }
}

.item {
  display: flex;
  flex-flow: column nowrap;
  gap: 10px;
  // align-items: flex-end;
  margin-top: 20px;
  padding: 25px;

  &:first-child {
    margin-top: 0;
  }
  > p:last-child {
    margin-bottom: 0;
  }
}

.border {
  border: 1px solid var(--color-mercury);
  border-radius: 10px;
}

.redText {
  color: red;
}

:global(.ar) {
  .item {
    // align-items: flex-end;
  }
}

:global(.en) {
  .item {
    // align-items: flex-start;
  }
}

.itemType {
  font-size: 20px;
  font-weight: bold;
  line-height: 20px;

  span{
    display: inline-block;
  }
  // display: flex;
  // direction: rtl;
  // gap: 20px;
  span:nth-child(1) {
    min-width: 250px;
  }
  span:nth-child(2) {
    font-weight: normal;
  }
}

.itemText {
  margin-top: 20px;
  font-size: 30px;
  line-height: 37px;
}

@media all and (min-width: 768px) {
  .info {
    align-items: flex-start;
    justify-content: space-between;
  }

  :global(.ar) {
    .info {
      flex-flow: row nowrap;
    }
  }

  :global(.en) {
    .info {
      flex-flow: row-reverse nowrap;
    }
  }
}

@media all and (min-width: 960px) {
  .info {
    padding: 50px 0;
    padding-top: 0;
  }

  .title {
    font-size: 50px;
    line-height: 60px;
  }

  .itemsRow {
    flex-flow: row nowrap;
  }

  .item {
    // margin-left: 40px;
    margin-top: 0;

    &:first-child {
      margin-left: 0;
    }
  }
}

@media all and (min-width: 1280px) {
  // .item {
  //   margin-left: 100px;
  // }

  .itemType {
    font-size: 20px;
    line-height: 24px;
  }

  .itemText {
    font-size: 35px;
    line-height: 42px;
  }
}
