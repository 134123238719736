.totals {
  padding-top: 30px;
  padding-bottom: 50px;
  border-top: 1px solid var(--color-silver);
  border-bottom: 1px solid var(--color-silver);
}

.total {
  display: flex;
  // flex-flow: row nowrap;
  align-items: center;
  border: 1px solid var(--color-mercury);
  border-bottom: 0;

  &:first-child {
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;

    .totalText {
      border-top-right-radius: 10px;
      border-top-left-radius: 10px;
    }
  }

  &:last-child {
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    border-bottom: 1px solid var(--color-mercury);

    .totalText {
      border-bottom-right-radius: 10px;
      border-bottom-left-radius: 10px;
    }
  }
}

.totalValue {
  flex: 1;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  padding: 20px 0;
  font-size: 20px;
  line-height: 19px;
}

.totalText {
  flex: 1;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  padding: 20px 0;
  font-size: 16px;
  line-height: 19px;
  background-color: var(--color-aqua-spring);
  // border-left: 1px solid var(--color-mercury);
}

:global(.ar) {
  .total {
    flex-flow: row nowrap;
  }

  .totalText {
    border-left: 1px solid var(--color-mercury);
    direction: rtl;
  }
}

:global(.en) {
  .total {
    flex-flow: row-reverse nowrap;
  }
  .totalText {
    border-right: 1px solid var(--color-mercury);
    direction: ltr;
  }
}

@media all and (min-width: 960px) {
  .totals {
    // align-self: flex-end;
    width: 750px;
  }

  :global(.ar) {
    .totals {
      align-self: flex-end;
    }
  }

  :global(.en) {
    .totals {
      align-self: flex-start;
    }
  }
}
