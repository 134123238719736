.uploadContainer {
    display: flex !important;
    justify-content: center;
}

.chooseFile {
    background-color: var(--color-dodger-blue);
    border-color: var(--color-dodger-blue);
    padding: 0.5rem 1.25rem;
    &:hover {
        background-color: var(--color-dodger-blue) !important;
        border-color: var(--color-dodger-blue) !important;
    }
}