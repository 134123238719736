.icon {
  width: 24px;
  height: 24px;
  color: var(--color-dodger-blue);
  cursor: pointer;
  transition: all .15s ease-out;

  &:hover {
    color: rgb(12, 134, 247);
  }
}
