.button {
  position: relative;
  display: flex;
  // flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  height: 50px;
  font-family: "Tahoma", sans-serif;
  font-size: 20px;
  line-height: 24px;
  font-weight: 400;
  border-radius: 10px;
  outline: none;
  cursor: pointer;
  transition: all .15s ease-out;

  &:disabled {
    cursor: default;
    pointer-events: none;
  }
}

.contained {
  color: var(--color-white);
  background-color: var(--color-dodger-blue);
  border: 0;

  &:hover {
    background-color: rgb(12, 134, 247);
  }
}

.outlined {
  color: var(--color-dodger-blue);
  background-color: transparent;
  border: 1px solid var(--color-dodger-blue);

  &:hover {
    background-color: rgb(12, 134, 247);
    border-color: rgb(12, 134, 247);
    color: var(--color-white);
  }
}

// .loading {
//   margin-left: 10px;
// }

.loadingInner {
  border-color: var(--color-white) transparent transparent transparent;
}

:global(.ar) {
  .button {
    flex-flow: row nowrap;
  }
  .loading {
    margin-left: 10px;
  }
}
:global(.en) {
  .button {
    flex-flow: row-reverse nowrap;
  }
  .loading {
    margin-right: 10px;
  }
}
