.actions {
  display: flex;
  // flex-flow: row nowrap;
  align-items: center;
}

.action {
  width: 190px;
  // margin-left: 20px;

  // &:first-child {
  //   margin-left: 0;
  // }
}

:global(.en) {
  .title {
    text-align: left;
  }

  .actions {
    flex-flow: row-reverse nowrap;
  }
  .action {
    margin-right: 20px;

    &:first-child {
      margin-right: 0;
    }
  }
}
:global(.ar) {
  .title {
    text-align: right;
  }
  .actions {
    flex-flow: row nowrap;
  }
  .action {
    margin-left: 20px;

    &:first-child {
      margin-left: 0;
    }
  }
}
