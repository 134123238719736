.info {
  padding-top: 20px;
}

.item {
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding-bottom: 20px;
  border-bottom: 1px solid var(--color-mercury);
  // margin-top: 50px;

  &:first-child {
    margin-top: 0;
  }
}

.itemTitle {
  font-size: 22px;
  line-height: 22px;
  margin-bottom: 30px;
  color: #0c0c0c;
  font-family: 'Tahoma', sans-serif;
}

.itemInner {
  display: grid;
  &.twoCol {
    grid-template-columns: 1fr 1fr;
  }
  &.threeCol {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

.itemCell {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 40px;
  > * {
    margin: 0;
  }
  &:first-child {
    margin-top: 0;
  }
}

:global(.ar) {
  .itemInner {
    direction: rtl;
  }
  .itemCell {
    direction: rtl;
  }
}

:global(.en) {
  .itemInner {
    direction: ltr;
  }
  .itemCell {
    direction: ltr;
  }
}

.itemCellType {
  font-size: 20px;
  font-weight: bold;
  line-height: 19px;
  color: #0c0c0c;
  font-family: 'Tahoma', sans-serif;
}

.itemCellValue {
  font-size: 20px;
  line-height: 24px;
}

@media all and (min-width: 1280px) {
  .itemCell {
    margin-top: 0;
    margin-left: 70px;

    &:first-child {
      margin-left: 0;
    }
  }
}

.borderTop {
  border-top: 1px solid var(--color-mercury);
}
