.rateButton {
    width: 10vw;
    min-width: fit-content;
    z-index: 1000;
    height: 6vh;
    position: fixed;
    background-color: #a11e18;
    border-radius: 10px 10px 0 0;
    left: 0px;

    &:hover {
        background-color: #a11e18;
    }
}

.selected {
    background-color: #dd0e21 !important;
    border-radius: 50%;
}

.modal {
    font-family: "Tahoma", sans-serif;
}

:global(.ar) {
    .modal {
        direction: rtl;
    }
}