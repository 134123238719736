.modal {
    width: 640px;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
}

.title {
    color: var(--color-dodger-blue);
    font-size: 30px;
    line-height: 36px;
}

.text {
    margin-top: 30px;
    font-size: 18px;
    line-height: 22px;
}

.button {
    width: 200px;
    margin-top: 40px;
    margin: 40px 5px 0
}