.page {
  display: flex;
  flex-flow: column nowrap;
  padding: 50px 20px;
  max-width: 1720px;
  margin: 0 auto;
}

.header {
  display: flex;
  flex-flow: column-reverse nowrap;
}

.formInner {
  display: flex;
  flex-flow: column nowrap;
}

.inner {
  display: flex;
  flex-flow: column nowrap;
  margin-top: 50px;
}

.form {
  display: flex;
  flex-flow: column nowrap;
  position: relative;
  border: 1px solid var(--color-silver);
  border-top: 0;
  border-radius: 10px;
  padding: 30px;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 10px;
    background-color: var(--color-dodger-blue);
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
  }
}

.actions {
  margin-top: 50px;
  display: flex;
  flex-flow: column nowrap;
}

.action {
  margin-top: 20px;

  &:first-child {
    margin-top: 0;
  }
}

@media all and (min-width: 960px) {
  .header {
    align-items: center;
    justify-content: space-between;
  }

  .actions {
    align-self: center;
  }

  .action {
    width: 260px;
    height: 100px;
    margin-top: 0;
  }

  :global(.ar) {
    .header {
      flex-flow: row-reverse nowrap;
    }

    .actions {
      flex-flow: row nowrap;
    }

    .action {
      margin-left: 20px;

      &:first-child {
        margin-left: 0;
      }
    }
  }

  :global(.en) {
    .header {
      flex-flow: row nowrap;
    }

    .actions {
      flex-flow: row-reverse nowrap;
    }

    .action {
      margin-right: 20px;

      &:first-child {
        margin-right: 0;
      }
    }
  }
}
