// .info {
//   display: flex;
//   flex-flow: column nowrap;
//   margin-top: 30px;
// }

// .infoRow {
//   flex: 1;
//   display: flex;
//   flex-flow: column nowrap;
//   margin-top: 25px;

//   // margin-right: 100px;
//   // margin-left: 100px;

//   &:first-child {
//     margin-top: 0;
//   }
// }

// .item {
//   margin-top: 25px;
//   padding: 30px 0;
//   flex: 1;
//   display: flex;
//   flex-flow: column nowrap;
//   align-items: center;
//   justify-content: center;
//   border-radius: 10px;
//   border: 1px solid var(--color-mercury);

//   &:first-child {
//     margin-top: 0;
//   }
// }

// .title {
//   font-size: 20px;
//   line-height: 24px;
//   color: var(--color-dodger-blue);
//   text-align: center;
// }
// :global(.ar) {
//   .title {
//     font-size: 20px;
//     line-height: 24px;
//     color: var(--color-dodger-blue);
//     text-align: center;
//   }
// }

// .text {
//   margin-top: 15px;
//   font-size: 35px;
//   line-height: 42px;
//   text-align: center;
// }

// @media all and (min-width: 480px) {
//   .item {
//     margin-top: 0;
//   }

//   :global(.ar) {
//     .infoRow {
//       flex-flow: row nowrap;
//     }
//     .item {
//       margin-left: 25px;

//       &:first-child {
//         margin-left: 0;
//       }
//     }
//   }

//   :global(.en) {
//     .infoRow {
//       flex-flow: row-reverse nowrap;
//     }
//     .item {
//       margin-right: 25px;

//       &:first-child {
//         margin-right: 0;
//       }
//     }
//   }
// }

// @media all and (min-width: 768px) {
//   .infoRow {
//     margin-top: 0;
//   }

//   :global(.ar) {
//     .info {
//       flex-flow: row nowrap;
//     }
//     .infoRow {
//       margin-left: 25px;

//       &:first-child {
//         margin-left: 0;
//       }
//     }
//   }

//   :global(.en) {
//     .info {
//       flex-flow: row-reverse nowrap;
//     }
//     .infoRow {
//       margin-right: 25px;

//       &:first-child {
//         margin-right: 0;
//       }
//     //   flex-flow: column wrap;
//     // }
//     // .infoRow {
//     //   margin-top: 25px;
//     }
//   }
// }

.info {
  display: flex;
  flex-flow: column nowrap;
  margin-top: 30px;
}

.infoRow {
  flex: 1;
  display: flex;
  flex-flow: column nowrap;
  margin-top: 25px;

  margin-right: 100px;
  margin-left: 100px;

  &:first-child {
    margin-top: 0;
  }
}

.item {
  margin-top: 25px;
  padding: 30px 0;
  flex: 1;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  border: 1px solid var(--color-mercury);

  &:first-child {
    margin-top: 0;
  }
}

.title {
  font-size: 20px;
  line-height: 24px;
  color: var(--color-dodger-blue);
  text-align: center;
}
:global(.ar) {
  .title {
    font-size: 20px;
    line-height: 24px;
    color: var(--color-dodger-blue);
    text-align: center;
  }
}

.text {
  margin-top: 15px;
  font-size: 35px;
  line-height: 42px;
  text-align: center;
}

@media all and (min-width: 768px) {
  .item {
    margin-top: 0;
  }

  :global(.ar) {
    .infoRow {
      flex-flow: row nowrap;
    }
    .item {
      margin-left: 25px;

      &:first-child {
        margin-left: 0;
      }
    }
  }

  :global(.en) {
    .infoRow {
      flex-flow: row-reverse nowrap;
    }
    .item {
      margin-right: 25px;

      &:first-child {
        margin-right: 0;
      }
    }
  }
}

@media all and (max-width: 500px) {
  .text {
    font-size: 25px;
  }
}
