.page {
  display: flex;
  flex-flow: column nowrap;
  padding: 50px 20px;
  max-width: 1720px;
  margin: 0 auto;
}

.header {
  display: flex;
  flex-flow: column-reverse nowrap;
  margin-bottom: 64px;
}

.title {
  font-size: 20px;
  line-height: 24px;
  color: var(--color-dodger-blue);
  margin-bottom: 20px;
}

.inner {
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  border: 1px solid var(--color-mercury);
  border-top: 0;
  border-bottom: 0;
  border-radius: 10px;
  padding: 40px 10px;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 10px;
    background-color: var(--color-dodger-blue);
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
  }

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 10px;
    background-color: var(--color-dodger-blue);
    border-bottom-right-radius: 10px;

    border-bottom-left-radius: 10px;
  }
}

.billingHeader {
  display: flex;
  // margin-bottom: 20px;
  align-items: center;
  justify-content: space-between;
}

.bilingTitle {
  font-size: 30px;
  line-height: 60px;
  color: var(--color-black);
  margin-bottom: 20px;
  text-align: center;
  justify-content: center;
  align-self: center;
}
.invoiceTitle p {
  font-size: 30px;
  line-height: 60px;
  color: var(--color-black);
  margin-bottom: 20px;
  text-align: center;
  justify-content: center;
  align-self: center;
  margin-bottom: 40px;
  margin-top: -10px;
}
p.salesIncomeTax {
  font-size: 30px;
  line-height: 60px;
  color: var(--color-black);
  margin-bottom: 20px;
  text-align: center;
  justify-content: center;
  align-self: center;
}

.jofotaraImage {
  // margin-bottom: 20px;
  text-align: center;
  justify-content: center;
  align-self: center;
}

.actions {
  margin-top: 80px;
  display: flex;
  flex-flow: column nowrap;
}

.action {
  margin-top: 20px;

  &:first-child {
    margin-left: 0;
  }
}

.totals {
  border-top: 0;
}

@media all and (min-width: 768px) {
  .inner {
    padding: 50px;
  }
}

@media all and (min-width: 960px) {
  .header {
    flex-flow: row-reverse nowrap;
    align-items: center;
    justify-content: space-between;
  }

  .action {
    width: 260px;
    height: 100px;
    margin-left: 20px;
    margin-top: 0;

    &:first-child {
      margin-left: 0;
    }
  }

  .actions {
    align-self: center;
    flex-flow: row nowrap;
  }
}

.pageFooter {
  width: 100%;
  margin-top: 20px;
}

.footerBorder {
  display: flex;
  flex-direction: column;
  gap: 5px;
  div:first-child {
    width: 100%;
    height: 5px;
    background-color: var(--color-dodger-blue);
  }
  div:last-child {
    width: 100%;
    height: 2px;
    background-color: var(--color-dodger-blue);
  }
}

.footerText {
  display: flex;
  gap: 40px;
  margin-top: 10px;
  p {
    font-weight: bold;
    span {
      font-weight: normal;
    }
  }
}

:global(.ar) {
  .footerText {
    direction: rtl;
  }
}

:global(.en) {
  .footerText {
    direction: ltr;
  }
}
