.typography {
    color: var(--color-black);
}

.tahoma {
    font-family: 'Tahoma', sans-serif;
}

.gess {
    font-family: "GE SS", sans-serif;
}

.regular {
  font-weight: 400;
}

.medium {
  font-weight: 500;
}

.semiBold {
  font-weight: 600;
}

.bold {
  font-weight: 700;
}

// :global(.ar) {
//   .typography {
//     text-align: right;
//   }
// }

// :global(.en) {
//   .typography {
//     text-align: left;
//   }
// }