.section {
  display: flex;
  flex-flow: column nowrap;
  border-top: 1px solid var(--color-silver);

  &:first-child {
    border-top: 0;
  }
}

.title {
  font-size: 22px;
  font-weight: bold;
  line-height: 22px;
  margin-top: 20px;
  color: var(--color-dodger-blue);
}

.invoiceKindContainer {
  display: flex;
  flex-direction: column;
  margin-top: 10px;

  .subTitle {
    font-weight: bold;
    font-size: 20px; // Add other styling properties as needed
    color: #0c0c0c; // Add other styling properties as needed
    margin-bottom: 15px; // Add other styling properties as needed
  }
  .input {
    display: flex;
    margin-top: 10px;
  }
}

.groupGrid {
  margin-top: 30px;
}

.fieldLabel {
  font-size: 20px;
  font-weight: bold;
  line-height: 19px;
  color: #0c0c0c;
  font-family: 'Tahoma', sans-serif;
  margin-bottom: 15px;
}

.button {
  align-self: center;
  margin-top: 20px;
  width: 100%;
}

.table {
  margin-top: 30px;
  margin-bottom: 30px;
}

.tableHeaderText {
  font-size: 16px;
  line-height: 19px;
}

.actionCell {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.trashIcon {
  margin-right: 15px;
  width: 24px;
  height: 24px;
  color: var(--color-dodger-blue);
  cursor: pointer;
}

.editIcon {
  width: 24px;
  height: 24px;
  color: var(--color-dodger-blue);
  cursor: pointer;
}

@media all and (min-width: 768px) {
  .group {
    flex-flow: row wrap;
  }

  :global(.ar) {
    .group {
      justify-content: flex-end;
    }
  }

  .groupGrid {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    grid-gap: 5px;
  }

  :global(.ar) {
    .groupGrid {
      justify-content: end;
      flex-direction: row-reverse;
    }
  }

  .fieldControl {
    flex: 1;
    margin-left: 24px;

    &:first-child {
      margin-left: 0;
    }
  }
}

@media all and (min-width: 960px) {
  .button {
    width: 540px;
  }
}


.invoiceTypeNote{
  margin-top: -20px;
  padding-bottom: 20px;
  color: var(--color-red);
}

.errorText {
  // font-size: 14px;
  color: var(--color-tamarillo);
}