.layout {
  display: flex;
  flex-flow: column nowrap;
  position: relative;
  min-height: 100vh;
}

.line {
  width: 100%;
  height: 15px;
  background-color: var(--color-dodger-blue);
}

.inner {
  position: relative;
  flex: 1;
  padding-bottom: 10rem;
  @media(max-width: 767px){
    padding-bottom: 20rem;
  }
}
