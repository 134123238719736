.input {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  margin-top: 50px;
}

.field {
  width: 20px;
  height: 30px;
  margin-left: 10px;
  padding: 22px;
  font-size: 16px;
  font-family: "Tahoma", sans-serif;
  line-height: 1;
  text-align: center;
  border: 1px solid var(--color-mercury);
  border-radius: 10px;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
      -webkit-appearance: none;
  }

  &:first-child {
    margin-left: 0;
  }
}

@media all and (min-width: 480px) {
  .field {
    width: 60px;
    height: 60px;
    margin-left: 30px;
  }
}
