.filters {
  // align-self: flex-end;
  display: flex;
  // flex-flow: row nowrap;
  align-items: center;
  // margin: 30px 0;
  // padding-right: 10px;
}

.items {
  display: flex;
  // flex-flow: row nowrap;
}

.item {
  display: flex;
  // flex-flow: row nowrap;
  align-items: center;
  background-color: var(--color-aqua-spring);
  border-radius: 10px;
  padding: 12px 10px;
  // margin-left: 20px;

  // &:first-child {
  //   margin-left: 0;
  // }
}

.itemIcon {
  width: 14px;
  height: 14px;
  color: var(--color-black);
  cursor: pointer;
  transition: all 0.15s ease-out;

  &:hover {
    color: var(--color-red);
  }
}

.itemText {
  // margin-left: 16px;
  flex: 1;
  font-size: 20px;
  line-height: 23px;
}

.clearIconWrapper {
  display: flex;
  // flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border: 1px solid var(--color-red);
  border-radius: 50%;
  margin: 0 14px;
  cursor: pointer;
  transition: all 0.15s ease-out;

  &:hover {
    background-color: var(--color-red);

    .clearIcon {
      color: var(--color-white);
    }
  }
}

.clearIcon {
  width: 14px;
  height: 14px;
  color: var(--color-red);
  transition: all 0.15s ease-out;
}

.filterBtn {
  width: 50px;
  height: 50px;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--color-mercury);
  // margin-right: 10px;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.15s ease-out;

  &:hover {
    background-color: var(--color-dodger-blue);
    border-color: var(--color-dodger-blue);

    .filterIcon {
      color: var(--color-white);
    }
  }
}

.filterIcon {
  width: 24px;
  height: 24px;
  color: var(--color-dodger-blue);
  transition: all 0.15s ease-out;
}

.input {
  width: 330px;
}

.inputField {
  padding: 11px 15px;
  // padding-left: 47px;
}

.searchIcon {
  width: 24px;
  height: 24px;
  color: var(--color-dodger-blue);
}

:global(.ar) {
  .filters {
    flex-flow: row nowrap;
    align-self: flex-end;
    padding-right: 10px;
  }
  .items {
    flex-flow: row nowrap;
  }

  .item {
    flex-flow: row nowrap;
    margin-left: 20px;

    &:first-child {
      margin-left: 0;
    }
  }

  .itemText {
    margin-left: 16px;
  }

  .clearIconWrapper {
    flex-flow: row nowrap;
  }
  .filterBtn {
    margin-right: 10px;
  }
  .inputField {
    padding-left: 47px;
  }
}

:global(.en) {
  .filters {
    flex-flow: row-reverse nowrap;
    align-self: flex-start;

    padding-left: 10px;
  }
  .items {
    flex-flow: row-reverse nowrap;
  }
  .item {
    flex-flow: row-reverse nowrap;
    margin-right: 20px;

    &:first-child {
      margin-right: 0;
    }
  }
  .itemText {
    margin-right: 16px;
  }
  .clearIconWrapper {
    flex-flow: row-reverse nowrap;
  }
  .filterBtn {
    margin-left: 10px;
  }
  .inputField {
    padding-right: 47px;
  }
}
