.select {
  display: flex;
  // flex-flow: row nowrap;
  align-items: center;
  cursor: pointer;

  &:hover {
    .icon,
    .text {
      color: rgb(12, 134, 247);
    }
  }
}

.icon {
  color: var(--color-dodger-blue);
  transition: all .15s ease-out;
}

.text {
  // margin-left: 10px;
  color: var(--color-dodger-blue);
  transition: all .15s ease-out;
}


:global(.ar) {
  .select {
    flex-flow: row nowrap;
  }
  .text {
    margin-left: 10px;
  }
}

:global(.en) {
  .select {
    flex-flow: row-reverse nowrap;
  }
  .text {
    margin-right: 10px;
  }
}