.form {
    display: flex;
    flex-flow: column nowrap;  
    width: 100%;
  }
  
  .button {
    margin-top: 65px;
    width: 100%;
    height: 60px;
    font-weight: 700;
  }
  
  .info {
    align-self: center;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    margin-top: 25px;
  }
  .errorMessage {
    color: var(--color-tamarillo);
  }
  
  .infoItem {
    margin-top: 25px;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
  
    &:first-child {
      margin-top: 0;
    }
  }
  
  .infoText {
    margin-left: 6px;
  }
  
  .passwordIcon {
    width: 24px;
    height: 24px;
    color: var(--color-dodger-blue);
    cursor: pointer;
  }
  