.group {
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-end;

  > * {
    flex: 1;
    margin-left: 24px;

    &:first-child {
      margin-left: 0;
    }
  }
}