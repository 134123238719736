.form {
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
}

.button {
  margin-top: 65px;
  height: 60px;
  font-weight: 700;
  width: 100%;
}

.info {
  align-self: center;
  margin-top: 25px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.infoText {
  margin-left: 6px;
}

.passwordIcon {
  width: 24px;
  height: 24px;
  color: var(--color-dodger-blue);
  cursor: pointer;
}

.taxNumber {
  position: relative;
}

.taxNumberLoading {
  position: absolute;
  top: 57px;
  right: 20px;
}
