.control {
  display: flex;
  flex: 1;
  // flex-flow: row-reverse nowrap;
  width: 100%;
  min-height: 60px;
  // padding: 0 12px 0 16px;
  border: 1px solid var(--color-mercury);
  border-radius: 8px;
  box-shadow: none;
  cursor: pointer;
  transition: border-color 0.15s ease-out;

  &.controlHaveError {
    // padding: 0 11px 0 15px;
    border: 2px solid var(--color-tamarillo);
  }

  &.activeControl {
    .indicator {
      transform: rotate(180deg);
      transition: all 0.15s ease-out;
    }
  }

  .startAdornment {
    display: flex;
    align-items: center;
    // margin-right: 8px;
  }

  .indicator {
    width: 16px;
    height: 16px;
    color: var(--color-dodger-blue);
    transition: all 0.15s ease-out;
  }
}

.controlFocused {
  border-color: var(--color-dodger-blue);
}

.container {
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  min-height: 48px;
}

.clear {
  position: absolute;
  top: -24px;
  // right: 0;
  color: var(--color-red-primary);
}

.placeholder {
  font-size: 16px;
  line-height: 1;
}

.removeContainer {
  width: 20px;
  height: 20px;
  // margin-left: 8px;
  background-color: var(--color-white-20);
  border-radius: 2px;

  &:hover {
    background-color: var(--color-white-40);
  }

  &:active {
    background-color: var(--color-white-60);
  }
}

.removeIcon {
  width: 20px;
  height: 20px;
  color: var(--color-white-70);
}

.menuList {
  margin-top: 8px;
  padding: 8px 0;
  background-color: var(--color-white);
  border-radius: 8px;
  border: 1px solid var(--color-mercury);
}

.valueContainer {
  display: flex;
  flex: 1;
  // flex-flow: row-reverse wrap;
  align-items: center;
}

.option {
  display: flex;
  flex-flow: column nowrap;
  // align-items: flex-end;
  justify-content: center;
  height: 48px;
  padding: 0 22px;
  font-size: 16px;
  font-family: 'Tahoma', sans-serif;
  line-height: 28px;
  // text-align: right;
  cursor: pointer;
  transition: all 0.15s ease-out;

  &:hover {
    background-color: rgba(51, 157, 255, 0.1);
    color: var(--color-dodger-blue);
  }
}

// .checkbox {
//   margin-right: 18px;
// }

.noOption {
  margin: 7px 16px;
  color: var(--color-grey-dark);
}

.tag {
  display: flex;
  // flex-flow: row nowrap;
  align-items: center;
  // margin: 4px 8px 4px 0;
  padding: 4px 8px;
  color: var(--color-white-primary);
  background-color: var(--color-blue-primary);
  border-radius: 4px;
}

// .loading {
//   margin-left: 10px;
// }

.indicatorsContainer {
  display: flex;
  // flex-flow: row-reverse nowrap;
  align-items: center;
}

:global(.ar) {
  .control {
    flex-flow: row-reverse nowrap;
    padding: 0 12px 0 16px;

    &.controlHaveError {
      padding: 0 11px 0 15px;
    }

    .startAdornment {
      margin-right: 8px;
    }
  }
  .controlFocused {
    padding: 0 11px 0 15px;
  }
  .clear {
    right: 0;
  }
  .removeContainer {
    margin-left: 8px;
  }
  .valueContainer {
    flex-flow: row-reverse wrap;
  }

  .option {
    align-items: flex-end;
    text-align: right;
  }
  .checkbox {
    margin-right: 18px;
  }

  .tag {
    flex-flow: row nowrap;
    margin: 4px 8px 4px 0;
  }

  .loading {
    margin-left: 10px;
  }

  .indicatorsContainer {
    flex-flow: row-reverse nowrap;
  }
}

:global(.en) {
  .control {
    flex-flow: row nowrap;
    padding: 0 16px 0 12px;

    &.controlHaveError {
      padding: 0 15px 0 11px;
    }

    .startAdornment {
      margin-left: 8px;
    }
  }
  .controlFocused {
    padding: 0 15px 0 11px;
  }
  .clear {
    left: 0;
  }
  .removeContainer {
    margin-right: 8px;
  }
  .valueContainer {
    flex-flow: row wrap;
  }
  .option {
    align-items: flex-start;
    text-align: left;
  }
  .checkbox {
    margin-left: 18px;
  }

  .tag {
    flex-flow: row-reverse nowrap;
    margin: 4px 0 4px 8px;
  }

  .loading {
    margin-right: 10px;
  }

  .indicatorsContainer {
    flex-flow: row nowrap;
  }
}

.disabled-select {
  > div {
    background-color: var(--color-concrete) !important;
    border-color: var(--color-concrete) !important;
    pointer-events: none;
  }
}
