.page {
    display: flex;
    flex-flow: column nowrap;
    padding: 80px 20px;
    max-width: 1720px;
    margin: 0 auto;
    font-family: "Tahoma", sans-serif;;
}

.header {
    display: flex;
    flex-flow: column-reverse nowrap;
}


@media all and (min-width: 960px) {
    .header {
        align-items: center;
        justify-content: space-between;
    }

    :global(.ar) {
        .header {
            flex-flow: row-reverse nowrap;
        }
    }

    :global(.en) {
        .header {
            flex-flow: row nowrap;
        }
    }
}

.title {
    color: var(--color-dodger-blue);
    font-size: 30px;
    line-height: 36px;
    margin-top: 20px;
}

.links {
    > p {
        margin-bottom: 15px;
        margin-top: 15px;
        > a {
            text-decoration: none;
        }
    }
}

.container {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
}

:global(.ar) {
    .container{
        align-items: flex-end;
    }
}

.inner {
    // width: 50%;
    // border: solid;
    // border-style: outset;
    padding: 15px;
    border-radius: 15px;
    // box-shadow: 0px 0px 7px 2px;
    margin-top: 20px;
}