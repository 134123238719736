.modalWrapper {
  padding: 50px 20px;
  min-width: 300px;
  margin: 50px 10px;
}

.modal {
  display: flex;
  flex-flow: column nowrap;
}

.modalCloseIcon {
  left: 40px;
}

.title {
  align-self: flex-end;
  font-size: 22px;
  line-height: 25px;
}

:global(.en) {
  .title {
    align-self: flex-start;
    font-size: 22px;
    line-height: 25px;
    margin-top: 20px;
  }
}

.form {
  padding-top: 30px;
  margin-top: 30px;
  border-top: 1px solid var(--color-mercury);
}

.formRow {
  display: flex;
  flex-flow: column nowrap;
}

.actions {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.action {
  width: 190px;
  margin-left: 20px;

  &:first-child {
    margin-left: 0;
  }
}

@media all and (min-width: 768px) {
  .modalWrapper {
    margin: 50px auto;
  }
}

@media all and (min-width: 960px) {
  .modalWrapper {
    padding: 30px 40px 50px 40px;
  }
  
}

@media all and (min-width: 1280px) {
  .modal {
    width: 1120px;
  }

  .formRow {
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
  }

  .field {
    width: 540px;
  }
}