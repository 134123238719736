.notes {
  margin-top: 50px;
}

.notesLabel {
  font-size: 22px;
  font-weight: bold;
  line-height: 22px;
  font-weight: 700;
  color: var(--color-dodger-blue);
  margin-bottom: 30px;
}

.notesText {
  font-size: 16px;
  line-height: 19px;
}