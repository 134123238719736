.caption {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
}

.prevIcon,
.nextIcon {
  width: 12px;
  height: 12px;
  cursor: pointer;
  transition: all .15s ease-out;

  &:hover {
    color: var(--color-dodger-blue);
  }
}

.text {
  width: 135px;
  margin: 0 30px;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
}