.header {
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  height: 120px;
  background-color: transparent;
  background-color: var(--color-white);
  padding: 0 20px;
}

.aside {
  display: none;
}

.inner {
  display: flex;
  align-items: center;
}

.nav {
  display: none;
}

.userInfo {
  display: none;
}

.navItem {
  padding: 48px 0 44px 0;
  border-bottom: 4px solid transparent;
}

.navItemSelected {
  border-bottom: 4px solid var(--color-dodger-blue);

  .navLink {
    color: var(--color-dodger-blue);
    font-weight: 700;
  }
}

.navLink {
  font-size: 20px;
  line-height: 24px;
  color: var(--color-white);
  cursor: pointer;
}

.logo {
  width: 180px;
}

.menuIcon {
  width: 24px;
  height: 24px;
  color: var(--color-dodger-blue);
  cursor: pointer;
}

@media all and (min-width: 1280px) {
  .header {
    padding: 0 50px;
  }

  .aside {
    display: flex;
    align-items: center;
  }

  .nav {
    display: flex;
    align-items: center;
    margin-right: 100px;
  }

  .userInfo {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
  }

  .navItem {
    margin-left: 50px;
  }

  .logo {
    width: 282px;
    height: 80px;
  }

  .menuIcon {
    display: none;
  }


  :global(.ar) {
    .header {
      flex-flow: row nowrap;
    }

    .aside {
      flex-flow: row nowrap;
    }

    .inner {
      flex-flow: row nowrap;
    }

    .nav {
      flex-flow: row nowrap;
    }
  }

  :global(.en) {
    .header {
      flex-flow: row-reverse nowrap;
    }

    .aside {
      flex-flow: row-reverse nowrap;
    }

    .inner {
      flex-flow: row-reverse nowrap;
    }

    .nav {
      flex-flow: row-reverse nowrap;
    }
  }
}

@media all and (min-width: 1920px) {
  .header {
    padding: 0 100px;
  }

  .navItem {
    margin-left: 100px;
  }
}

.text {
  font-size: 13px;
  line-height: 16px;
  color: var(--color-dodger-blue);
}

.caption {
  font-size: 20px;
  line-height: 24px;
  color: var(--color-dodger-blue);
}

.userIcon {
  width: 30px;
  height: 30px;
  color: var(--color-dodger-blue);
}