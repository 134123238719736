.modalWrapper {
  padding: 50px 20px;
  min-width: 300px;
  margin: 50px 10px;
}

.modal {
  display: flex;
  flex-flow: column nowrap;
}

.icon {
  align-self: center;
  width: 35px;
  height: 35px;
  color: var(--color-dodger-blue);
}

.title {
  align-self: center;
  margin-top: 15px;
  font-size: 20px;
  line-height: 25px;
}

.inner {
  margin-top: 50px;
  display: flex;
}

:global(.ar) {
  .inner {
    flex-flow: row nowrap;
  }
}

:global(.en) {
  .inner {
    flex-flow: row-reverse nowrap;
  }
}

.item {
  flex: 1;
  position: relative;
}

:global(.ar) {
  .item {
    margin-left: 10px;

    &:first-child {
      margin-left: 0;
    }
  }
}

:global(.en) {
  .item {
    margin-left: 10px;

    &:last-child {
      margin-left: 0;
    }
  }
}


.itemActive {
  .itemInner {
    background-color: var(--color-dodger-blue);
    border-color: var(--color-dodger-blue);
  }

  .itemTitle {
    color: var(--color-white);
  }

  .itemIcon {
    color: var(--color-white);
  }
}

.itemInner {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  padding: 30px 0;
  border: 1px solid var(--color-mercury);
  cursor: pointer;
  transition: all 0.15s ease-out;

  &:hover {
    background-color: var(--color-dodger-blue);
    border-color: var(--color-dodger-blue);

    .itemTitle {
      color: var(--color-white);
    }

    .itemIcon {
      color: var(--color-white);
    }
  }
}

.disabledItem {
  opacity: 0.5;
  pointer-events: none;
}

.itemTitle {
  margin-top: 15px;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  transition: all 0.15s ease-out;
}

.itemIcon {
  width: 55px;
  height: 55px;
  color: var(--color-dodger-blue);
}

.itemOptions {
  margin-top: 20px;
}

.itemOption {
  margin-top: 20px;
  height: 60px;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--color-silver);
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.15s ease-out;

  &:first-child {
    margin-top: 0;
  }

  &:hover {
    background-color: var(--color-dodger-blue);
    border-color: var(--color-dodger-blue);

    .itemOptionText {
      color: var(--color-white);
    }
  }
}

.itemOptionText {
  font-size: 25px;
  line-height: 30px;
  color: var(--color-black);
  text-align: center;
  transition: all 0.15s ease-out;
}

@media all and (min-width: 480px) {
  .icon {
    width: 50px;
    height: 50px;
  }

  .title {
    font-size: 25px;
    line-height: 30px;
  }

  .itemTitle {
    font-size: 20px;
    line-height: 25px;
  }

  .itemIcon {
    width: 80px;
    height: 80px;
  }
}

@media all and (min-width: 768px) {
  .modalWrapper {
    margin: 50px 20px;
  }
}

@media all and (min-width: 960px) {
  .modalWrapper {
    margin: 50px auto;
    padding: 50px;
  }

  .item {
    width: 350px;
    margin-left: 50px;
  }

  :global(.ar) {
    .item {
      margin-left: 50px;

      &:first-child {
        margin-left: 0;
      }
    }
  }

  :global(.en) {
    .item {
      margin-left: 50px;

      &:last-child {
        margin-left: 0;
      }
    }
  }

  .itemInner {
    padding: 70px 0;
  }

  .itemTitle {
    font-size: 25px;
    line-height: 30px;
  }

  .itemIcon {
    width: 110px;
    height: 110px;
  }
}

.uploadInner {
  height: 314px;
  justify-content: space-between;

  &:hover {
    background-color: var(--color-white);
    border-color: var(--color-mercury);

    .itemTitle {
      color: var(--color-dodger-blue);
    }

    .itemIcon {
      color: var(--color-dodger-blue);
    }
  }
}

.uploadInnerLoading {
  height: 314px;

  &:hover {
    background-color: var(--color-white);
    border-color: var(--color-mercury);

    .itemTitle {
      color: var(--color-dodger-blue);
    }

    .itemIcon {
      color: var(--color-dodger-blue);
    }
  }

  justify-content: center !important;
}