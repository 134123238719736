.input {
  position: relative;
  display: flex;
  width: 100%;
}

.field {
  width: 100%;
  padding: 16px 20px;
  color: var(--color-black);
  font-family: 'Tahoma', sans-serif;
  font-size: 22px;
  line-height: 1;
  border: 1px solid var(--color-mercury);
  border-radius: 8px;
  outline: none;
  transition: border-color 0.15s ease-out;

  &::placeholder {
    color: var(--color-grey-dark);
  }

  /* Chrome, Safari, Edge, Opera */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  &[type=number] {
    -moz-appearance: textfield;
  }

  &:disabled {
    background-color: var(--color-concrete);
    border-color: var(--color-concrete);
    pointer-events: none;
  }

  &:focus {
    border-color: var(--color-dodger-blue);
  }
}

.multiline {
  resize: none;
}

.haveError {
  border: 2px solid var(--color-tamarillo);
  padding: 14px 18px;
}

.startAdornment {
  position: absolute;
  top: 50%;
  // left: 20px;
  transform: translateY(-50%);
}

.endAdornment {
  position: absolute;
  top: 50%;
  // right: 20px;
  transform: translateY(-50%);
}

.searchIcon {
  color: rgb(110, 110, 110);
  font-size: 20px;
  margin-right: 0px;
  display: flex;
  cursor: pointer; 
  &:hover {
    text-decoration: underline; 
  }
}

:global(.ar){


  .input {
    flex-flow: row nowrap;
    align-items: flex-start;
  }
  
  .field {
    text-align: right;
  }
  
  .withStartAdorment {
    padding-left: 52px;
  
    &:focus {
      padding-left: 51px;
    }
  }
  
  .withEndAdorment {
    padding-right: 52px;
  
    &:focus {
      padding-right: 51px;
    }
  }
  
  .startAdornment {
    left: 20px;
  }
  
  .endAdornment {
    right: 20px;
  }
  .searchIcon {
    border-right: solid 1px rgb(110, 110, 110);
    padding: 5px 10px 5px 0;
  }

}
:global(.en) {
  .input {
    flex-flow: row-reverse nowrap;
    align-items: flex-end;
  }
  
  .field {
    text-align: left;
  }
  
  .withStartAdorment {
    padding-right: 52px;
  
    &:focus {
      padding-right: 51px;
    }
  }
  
  .withEndAdorment {
    padding-left: 52px;
  
    &:focus {
      padding-left: 51px;
    }
  }
  
  .startAdornment {
    right: 20px;
  }
  .searchIcon {
    border-left: solid 1px rgb(110, 110, 110);
    padding: 5px 0px 5px 10px;
  }
  .endAdornment {
    left: 20px;
  }
  
}
