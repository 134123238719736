.pagination {
    display: flex;
    margin: 20px;
    justify-content: center;
    a {
        color: black;
        float: left;
        padding: 8px 16px;
        text-decoration: none;
        transition: background-color .3s;
        cursor: pointer;
    }


    a:hover:not(.active) {
        background-color: #ddd;
    }
}

.active {
    background-color: var(--color-dodger-blue);
    color: white !important;
}