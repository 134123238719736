.popover[data-animation="shift-toward"][data-state="hidden"] {
  opacity: 0;
}
.popover[data-animation="shift-toward"][data-state="hidden"][data-placement^="top"] {
  transform: translateY(-10px);
}
.popover[data-animation="shift-toward"][data-state="hidden"][data-placement^="bottom"] {
  transform: translateY(10px);
}
.popover[data-animation="shift-toward"][data-state="hidden"][data-placement^="left"] {
  transform: translateX(-10px);
}
.popover[data-animation="shift-toward"][data-state="hidden"][data-placement^="right"] {
  transform: translateX(10px);
}
