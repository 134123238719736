.modalWrapper {
  padding: 50px 20px;
  min-width: 300px;
  margin: 50px 10px;
}

.modal {
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
}

.loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.animation {
  width: 230px;
}

.title {
  margin-top: 30px;
  font-size: 30px;
  line-height: 36px;
  color: var(--color-dodger-blue);
  text-align: center;
}

.text {
  margin-top: 30px;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
}
.phoneNumberText {
    justify-content: 'center';
    align-items: 'center';
    margin-top: 0;
  }

.caption {
  margin-top: 15px;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
}

.errorMessage{
  margin: 5px 0;
  color: var(--color-tamarillo);
}

.input {
  margin-top: 50px;
}

.button {
  margin-top: 50px;
  width: 200px;
}

@media all and (min-width: 480px) {
  .modalWrapper {
    margin: 50px 20px;
    padding: 50px;
  }
}

@media all and (min-width: 768px) {
  .modalWrapper {
    margin: 50px auto;
  }

  .modal {
     width: 440px;
     height: 595px;
  }
}