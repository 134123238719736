.control {
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  margin-bottom: 30px;
}

.label {
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 15px;
  color: var(--color-dodger-blue);
}

// .labelRequired {
//   &:after {
//     content: '*';
//     font-size: 16px;
//     line-height: 19px;
//     color: var(--color-dodger-blue);
//   }
// }


:global(.ar) {
  .labelRequired {
  
    &:before {
      content: '*';
      font-size: 16px;
      line-height: 19px;
      color: var(--color-red);
    }
  }
}

:global(.en) {
  .labelRequired {
  
    &:after {
      content: '*';
      font-size: 16px;
      line-height: 19px;
      color: var(--color-red);
    }
  }
}


.error {
  position: absolute;
  top: 100%;
  margin-top: 4px;
}

.errorText {
  font-size: 14px;
  color: var(--color-tamarillo);
}
.success {
  position: absolute;
  top: 100%;
  margin-top: 4px;
}

.successText {
  font-size: 14px;
  color: var(--color-success);
}

:global(.ar) {
  .control {
    align-items: flex-end;
  }
  .error {
    right: 0;
  }
  .success {
    right: 0;
  }
}
:global(.en) {
  .control {
    align-items: flex-start;
  }
  .error {
    left: 0;
  }
  .success {
    left: 0;
  }
}
