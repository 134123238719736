:global {
  :root {
    --color-dodger-blue: #339dff;
    --color-jagged-ice: #c7deec;
    --color-concrete: #f2f2f2;
    --color-mercury: #e2e2e2;
    --color-white: #fff;
    --color-black: #2e3045;
    --color-tamarillo: #a11d18;
    --color-success: #2ecc71;
    --color-silver: #CCCCCC;
    --color-aqua-spring: #F8FBFD;
    --color-shuttle-gray: #61656E;
    --color-silver-chalice: #ABAAAA;
    --color-red: #FF0101;
  }
}
