.cell {
  align-self: center;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.value {
  font-size: 20px;
  line-height: 23px;
  margin-left: 30px;
}

.dots {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  margin-left: 30px;
}

.dot {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: var(--color-black);
  margin-left: 4px;

  &:first-child {
    margin-left: 0;
  }
}

.icon {
  width: 24px;
  height: 24px;
  color: var(--color-dodger-blue);
  cursor: pointer;
  transition: all .15s ease-out;

  &:hover {
    color: rgb(12, 134, 247);
  }
}
