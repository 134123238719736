.tableWrapper {
  overflow: hidden;
}

.table {
  min-width: 300px;
  overflow: scroll;
  display: flex;
  flex-flow: column nowrap;
  border-radius: 10px;
  border: 1px solid var(--color-mercury);
  color: #0c0c0c;
  font-family: 'Tahoma', sans-serif;
}

.header {
  display: flex;
  // flex-flow: row nowrap;
  height: 85px;
  border-radius: 10px;
  background-color: var(--color-aqua-spring);
}

.headerCell {
  background-color: var(--color-aqua-spring);
}

.headerText {
  font-size: 20px;
  line-height: 24px;
  color: #0c0c0c;
  font-family: 'Tahoma', sans-serif;
  // text-align: right;
}

.body {
  display: flex;
  flex-flow: column nowrap;
}

// .fitContent {
//   width: fit-content;
// }

.row {
  display: flex;
  // flex-flow: row nowrap;
  min-height: 72px;
  background-color: var(--color-white-primary);
  border-bottom: 1px solid var(--color-mercury);
  user-select: none;

  &:last-child {
    border-bottom: 0;
  }
}

.updatedRow {
  background-color: rgba(25, 118, 210, 0.08);
  align-self: baseline;
}
.deletedRow {
  background-color: rgba(210, 25, 25, 0.08);
  align-self: baseline;
  position: relative;
}
.deletedRow::after {
  content: ' ';
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  height: 1px;
  width: 100%;
  background-color: #000000;
}
