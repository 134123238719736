.toast {
  position: relative;
  max-width: 430px;
  background-color: var(--color-red);
  border-radius: 8px;
  cursor: pointer;
}

.line {
  position: absolute;
  top: 8px;
  left: 8px;
  width: 4px;
  height: calc(100% - 16px);
  margin-right: 8px;
  border-radius: 8px;
}

.inner {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  position: relative;
}

.main {
  flex: 1;
  margin: 0 48px 0 12px;
}

.text {
  padding: 20px 0;
  color: var(--color-white);
}

.success {
  background-color: green;
}