.icon {
  width: 24px;
  height: 24px;
  color: var(--color-grey-dark);
  cursor: pointer;
  transition: all .15s ease-out;

  &:hover {
    color: var(--color-blue-primary);
  }
}
