.page {
  display: flex;
  flex-flow: column nowrap;
  padding-bottom: 80px;
}

.line {
  position: absolute;
  top: 0;
  width: 100%;
  height: 2px;
  background-color: var(--color-dodger-blue);
}

.title {
  position: absolute;
  top: 20px;
  right: 40px;
  font-size: 30px;
  line-height: 44px;
  color: var(--color-white);
}

.actions {
  width: calc(100% - 40px);
  align-self: center;
  margin-top: 80px;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
}

.action {
  width: 100%;
  max-width: 300px;
  display: flex;
  flex-flow: column ;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--color-silver);
  border-radius: 20px;
  padding: 25px 0;
  margin-top: 20px;
  cursor: pointer;
  transition: all .15s ease-out;

  &:not(.disabledAction):hover {
    background-color: var(--color-dodger-blue);
    border-color: var(--color-dodger-blue);

    .actionTitle {
      color: var(--color-white);
    }

    .actionIcon {
      color: var(--color-white);
    }
  }

}

.disabledAction {
  opacity: 0.5;
  cursor: default;
}

.actionIcon {
  width: 50px;
  height: 50px;
  color: var(--color-dodger-blue);
  transition: all .15s ease-out;
}

.actionTitle {
  margin-top: 15px;
  font-size: 25px;
  line-height: 30px;
  transition: all .15s ease-out;
}

.bg {
  width: 100%;
}
.update-user-btn{
  background: transparent;
  color: var(--color-black);
  border: none;
  text-decoration: underline;
}

@media all and (min-width: 480px) {
  .title {
    font-size: 50px;
    line-height: 64px;
  }
}

@media all and (min-width: 768px) {
  .title {
    font-size: 70px;
    line-height: 84px;
  }

  .actions {
    width: 700px;
    flex-flow: row wrap;
    justify-content: space-around;
  }

  :global(.ar) {
    .actions {
      flex-flow: row wrap;
    }
  }

  :global(.en) {
    .actions {
      flex-flow: row-reverse wrap;
    }
  }
}

@media all and (min-width: 1280px) {
  .bg {
    position: relative;
    margin-top: -100px;
    z-index: -1;
  }

  .title {
    top: 50px;
    right: 100px;
  }

  .actions {
    width: calc(100% - 100px);
    margin: 10px 50px 0 50px;
    justify-content: center;
  }

  .action {
    max-width: 300px;
    flex: 1;

    &:first-child {
      margin-left: 0;
    }
  }

  :global(.ar) {
    .title {
      right: 100px;
    }
    .action {
      margin-left: 30px;
    }
  }

  :global(.en) {
    .title {
      left: 100px;
    }
    .action {
      margin-right: 30px;
    }
  }
}

@media all and (min-width: 1920px) {
  .actions {
    width: auto;
  }

  .action {
    width: 300px;
  }

  :global(.ar) {
    .action {
      margin-left: 50px;
    }
  }

  :global(.en) {
    .action {
      margin-right: 50px;
    }
  }
}


