.notes {
  margin-top: 30px;
}

.notesLabel {
  font-size: 22px;
  font-weight: bold;
  line-height: 22px;
  font-weight: 700;
  color: var(--color-dodger-blue);
  margin-bottom: 12px;
}

@media all and (min-width: 960px) {
  .notes {
    // align-self: flex-end;
    width: 810px;
  }

  :global(.ar) {
    .notes {
      align-self: flex-end;
    }
  }

  :global(.en) {
    .notes {
      align-self: flex-start;
    }
  }
}
