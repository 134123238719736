.link {
  font-family: "Tahoma", sans-serif;
  font-size: 18px;
  line-height: 22px;
  font-weight: 700;
  color: var(--color-dodger-blue);
  text-decoration: underline;
  cursor: pointer;
  transition: all .15s ease-out;

  &:hover {
    color: rgb(12, 134, 247);
  }
}