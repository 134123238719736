.page {
  display: flex;
  flex-flow: column nowrap;
  padding: 50px 20px;
  max-width: 1720px;
  margin: 0 auto;
}

.loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.header {
  display: flex;
  flex-flow: column-reverse nowrap;
}

.button {
  width: 190px;
  margin-bottom: 35px;
}

.inner {
  position: relative;
  margin-top: 30px;
}

.actionCell {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
}

.editIcon {
  width: 24px;
  height: 24px;
  color: var(--color-dodger-blue);
  cursor: pointer;
}

:global(.ar) {
  .emptyText {
    margin-top: 30px;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
  }
}

:global(.en) {
  .emptyText {
    margin-top: 30px;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
  }
}

@media all and (min-width: 960px) {
  .header {
    // flex-flow: row-reverse nowrap;
    align-items: center;
    justify-content: space-between;
  }

  .button {
    margin-bottom: 0;
  }

  :global(.ar) {
    .header{
      flex-flow: row-reverse nowrap;
    }
  
  }
  
  :global(.en) {
    .header{
      flex-flow: row nowrap;
    }
  }
}


