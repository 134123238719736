.cell {
  flex: 1 0;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  padding: 10px 10px 0 10px;
  border-right: 1px solid var(--color-mercury);

  &:last-child {
    border-right: 0;
  }
}

.text {
  font-size: 20px;
  line-height: 24px;
  text-align: right;
  color: var(--color-black);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
